import React, { ComponentType, useState } from "react";
import { useEffectOnce } from "react-use";
import { RouteComponentProps } from "@reach/router";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "@gatsbyjs/reach-router";
import capitalize from "lodash/capitalize";
import useApiQuery from "~/hooks/useApiQuery";
import { useSignedInAuthState } from "~/hooks/authContext";
import usePromotionsCookie from "~/hooks/usePromotionsCookie";
import { useApiMutation } from "~/hooks/apiContext";
import { StudentDetail } from "~/backendApi/model";
import NewCheckoutPaymentModal, {
  PaymentOpenState,
} from "./payment/NewCheckoutPaymentModal";
import AddStudentModal from "./AddStudentModal";
import StudentsTable from "./StudentsTable";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import ChangeStudentPasswordModal from "./ChangeStudentPasswordModal";
import EmailDangerZone from "./EmailDangerZone";
import DashboardPage from "./DashboardPage";

const studentsQueryKey = ["students"];

const profileQueryKey = ["profile"];

const freeTrialPromos = ["rounded-admin"];

function EmailGuardianStudents() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const showDownloadInstructions = searchParams.get("showDownload") === "1";

  const { isLoading, error, data } = useApiQuery(
    studentsQueryKey,
    (apiClient) => apiClient.students(),
  );

  const queryClient = useQueryClient();
  const { user } = useSignedInAuthState();

  // Delete
  const [pendingDeleteStudent, setPendingDeleteStudent] = useState<
    StudentDetail | undefined
  >();
  const onDeleteComplete = () => {
    setPendingDeleteStudent(undefined);
    queryClient.invalidateQueries(studentsQueryKey);
  };

  // Change password
  const [pendingChangePasswordStudent, setPendingChangePasswordStudent] =
    useState<StudentDetail | undefined>();

  // Pay now
  const [showPayNow, setShowPayNow] = useState<PaymentOpenState | undefined>(
    undefined,
  );

  // Add student
  const [showAddStudent, setShowAddStudent] = useState(false);
  const onAddComplete = async (studentId: string) => {
    setShowAddStudent(false);
    setShowPayNow({
      studentId,
    });
    await queryClient.invalidateQueries(studentsQueryKey);
  };

  // Promotions
  const { data: profile, error: profileError } = useApiQuery(
    profileQueryKey,
    (apiClient) => apiClient.getProfile(),
  );
  const { viewedPromotions, removePromotionViews } = usePromotionsCookie();
  const {
    mutateAsync: createPromotionsViews,
    // error: createPromotionViewError
  } = useApiMutation<readonly string[]>(
    (client, promotions) => client.createPromotionsViews(promotions),
    {
      onSuccess() {
        queryClient.invalidateQueries(studentsQueryKey);
      },
    },
  );
  useEffectOnce(() => {
    if (viewedPromotions.length === 0) {
      return;
    }

    (async () => {
      await createPromotionsViews(viewedPromotions);
      removePromotionViews(viewedPromotions);
    })();
  });

  return (
    <>
      <ChangeStudentPasswordModal
        student={pendingChangePasswordStudent}
        onRequestClose={() => setPendingChangePasswordStudent(undefined)}
      />
      <ConfirmDeleteModal
        student={pendingDeleteStudent}
        onRequestClose={() => setPendingDeleteStudent(undefined)}
        onComplete={onDeleteComplete}
      />
      <NewCheckoutPaymentModal
        open={showPayNow}
        onRequestClose={() => setShowPayNow(undefined)}
      />
      <DashboardPage
        heading={`${capitalize(user.type)} Dashboard`}
        subHeading="Steps to Get Playing"
      >
        <StudentsTable
          showDownloadInstructions={showDownloadInstructions}
          viewStudentToPrefix="/dashboard/students"
          isLoading={isLoading}
          allowFreeTrial={freeTrialPromos.some((p) =>
            profile?.viewedPromotions?.includes(p),
          )}
          error={(error as any) ?? profileError}
          data={data}
          onShowPay={(d) => setShowPayNow({ studentId: d.id })}
          addStudent={
            user.loginType.type === "email"
              ? {
                  userType: user.type,
                  onAdd: () => setShowAddStudent(true),
                }
              : undefined
          }
          onDeleteStudent={setPendingDeleteStudent}
          onChangePasswordStudent={setPendingChangePasswordStudent}
        />
        {!isLoading && (
          <EmailDangerZone hasStudent={!!data && data.length > 0} />
        )}
      </DashboardPage>
      <AddStudentModal
        isOpen={showAddStudent}
        onRequestClose={() => setShowAddStudent(false)}
        onComplete={onAddComplete}
      />
    </>
  );
}

export default EmailGuardianStudents as ComponentType<RouteComponentProps>;
