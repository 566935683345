import React, { useEffect, useState } from "react";
import { usePrevious } from "react-use";
import omit from "lodash/omit";
import sum from "lodash/sum";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import Modal, { ModalProps } from "~/components/Modal";
import Button from "~/components/Button";
import ErrorMessages from "~/components/ErrorMessages";
import { formatMoney } from "~/utils/currency";
import { findOnly } from "~/utils/array";
import { useApiMutation } from "~/hooks/apiContext";
import { PaymentCheckoutSessionInput } from "~/backendApi/index";
import useApiQuery from "~/hooks/useApiQuery";
import LoadingSpinner from "~/components/LoadingSpinner";
import formatMonths from "~/utils/formatMonths";
import "./PaymentModal.css";

type PaymentOpenState = {
  readonly studentId: string;
};

type NewCheckoutPaymentModalProps = Pick<ModalProps, "onRequestClose"> & {
  readonly open: PaymentOpenState | undefined;
};

function NewCheckoutPaymentModal({
  open,
  onRequestClose,
}: NewCheckoutPaymentModalProps) {
  const {
    isLoading: sLoading,
    error: sError,
    data: sData,
  } = useApiQuery(["students"], (apiClient) => apiClient.students());
  const unsubscribedStudents = sData?.filter((s) => !s.isSubscribed) ?? [];

  // Subscription prices
  const {
    isLoading: pLoading,
    error: pError,
    data: pData,
  } = useApiQuery(["accessPrices"], (apiClient) => apiClient.accessPrices(), {
    staleTime: Infinity,
  });
  const accessPrices = sortBy(pData ?? [], (p) => p.numMonths);

  // amount
  const [selectedPrices, setSelectedPrices] = useState<
    Record<string, string | undefined>
  >({});
  const totalAmount =
    Object.values(selectedPrices).length === 0
      ? undefined
      : sum(
          Object.values(selectedPrices).map(
            (pLookupKey) =>
              findOnly(accessPrices, (a) => a.lookupKey === pLookupKey).price,
          ),
        );
  const isOpen = !!open;
  const previousOpen = usePrevious(isOpen);
  useEffect(() => {
    if (isOpen && !previousOpen && accessPrices.length > 0) {
      setSelectedPrices({
        [open.studentId]: accessPrices[accessPrices.length - 1].lookupKey,
      });
    }
  }, [isOpen, accessPrices, previousOpen, open]);

  // Checkout with stripe
  const {
    mutateAsync: createPaymentCheckoutSession,
    isLoading: cLoading,
    error: cError,
  } = useApiMutation<PaymentCheckoutSessionInput, string>(async (client, a) =>
    client.createPaymentCheckoutSession(a),
  );

  const onSubmit = async () => {
    // Very ugly, must be a better way
    const items = Object.values(
      mapValues(
        Object.entries(
          groupBy(
            Object.entries(selectedPrices),
            ([, priceLookupKey]) => priceLookupKey,
          ),
        ),
        ([priceLookupKey, pairs]: any) => ({
          priceLookupKey,
          studentIds: pairs.map(([studentId]: any) => studentId),
        }),
      ),
    );

    const successParams = new URLSearchParams();
    const allStudentIds = items.flatMap((i) => i.studentIds);
    allStudentIds.forEach((studentId) => {
      successParams.append("studentIds[]", studentId);
    });

    try {
      const redirectUrl = await createPaymentCheckoutSession({
        items,
        successUrl: `${origin}/dashboard/payment-complete/?${successParams.toString()}`,
        cancelUrl: `${origin}/dashboard/`,
      });
      window.location.href = redirectUrl;
    } catch {
      // Ignore since we're show error from mutation react hook
    }
  };

  const isLoading = cLoading || pLoading || sLoading;

  return (
    <Modal
      title="Payment"
      className="payment-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      disabled={isLoading}
    >
      <div style={{ overflowY: "scroll" }}>
        <ErrorMessages errors={[cError, pError, sError]} />
        <table className="payment-student-table">
          <thead>
            <tr>
              <th>Student</th>
              {accessPrices.map((a) => (
                <th key={a.lookupKey}>
                  {formatMonths(a.numMonths)} ({formatMoney(a.price)})
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!sLoading && unsubscribedStudents.length === 0 && (
              <tr>
                <td colSpan={2 + accessPrices.length}>
                  <em>You don't have any students without access</em>
                </td>
              </tr>
            )}
            {sLoading && (
              <tr>
                <td colSpan={2 + accessPrices.length}>
                  <LoadingSpinner />
                </td>
              </tr>
            )}
            {unsubscribedStudents.map((s) => (
              <tr key={s.id}>
                <td>{s.firstName ?? s.username}</td>
                {accessPrices.map((a) => (
                  <td key={a.lookupKey}>
                    <input
                      type="radio"
                      checked={selectedPrices[s.id] === a.lookupKey}
                      onChange={(e) => {
                        setSelectedPrices((p) => {
                          if (e.target.checked) {
                            return { ...p, [s.id]: a.lookupKey };
                          }
                          return omit(p, s.id);
                        });
                      }}
                      disabled={isLoading}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="modal-footer-buttons">
        <Button
          type="button"
          colour="red"
          size="small"
          variant="outline"
          onClick={onRequestClose}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type="button"
          colour="blue"
          size="small"
          variant="solid"
          disabled={isLoading || totalAmount === undefined}
          onClick={onSubmit}
        >
          Pay{totalAmount !== undefined && ` - ${formatMoney(totalAmount)}`}
        </Button>
      </div>
    </Modal>
  );
}

export type { PaymentOpenState };
export default NewCheckoutPaymentModal;
