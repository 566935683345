import React, { Fragment, ReactNode, useCallback, useState } from "react";
import { range } from "lodash";
import clsx from "clsx";
import { QueryKey } from "@tanstack/react-query";
import TeX from "@matejmazur/react-katex";
import { ApiClient } from "~/backendApi/index";
import ErrorMessages, { ErrorMessagesProps } from "~/components/ErrorMessages";
import LoadingSpinner from "~/components/LoadingSpinner";
import LegacySeo from "~/components/LegacySeo";
import Breadcrumbs, { BreadcrumbItem } from "~/components/Breadcrumbs";
import useApiQuery from "~/hooks/useApiQuery";
import { StudentDetail } from "~/backendApi/model";
import { GameTimes } from "~/backendApi/gameTimes";
import Button from "~/components/Button";
import chapters from "./chaptersData";
import DashboardPage from "./DashboardPage";
import ActivityProgress from "./ActivityProgress";
import "katex/dist/katex.min.css";
import "./BaseViewStudent.css";

type BaseViewStudentProps = {
  readonly gameTimesQueryKey: QueryKey;
  readonly gameTimesApiCall: (api: ApiClient) => Promise<GameTimes>;
  readonly queryKey: QueryKey;
  readonly apiCall: (
    api: ApiClient,
  ) => Promise<
    Pick<
      StudentDetail,
      | "id"
      | "username"
      | "firstName"
      | "monthOfBirth"
      | "yearOfBirth"
      | "totalChaptersCompleted"
    >
  >;
  readonly extraErrors?: ErrorMessagesProps["errors"];
  readonly breadcrumbItems?: ReadonlyArray<BreadcrumbItem>;
  readonly children?: ReactNode;
};

type SubContent =
  | {
      readonly type: "review";
    }
  | {
      readonly type: "topic";
      readonly topicIndex: number;
    };

function BaseViewStudent({
  gameTimesQueryKey,
  gameTimesApiCall,
  queryKey,
  apiCall,
  breadcrumbItems,
  extraErrors,
  children,
}: BaseViewStudentProps) {
  const { error, isLoading, data } = useApiQuery(queryKey, apiCall);

  const {
    error: timesError,
    isLoading: timesLoading,
    data: timesData,
  } = useApiQuery(gameTimesQueryKey, gameTimesApiCall);

  const totalChapterCompleted =
    data === undefined ? undefined : (data.totalChaptersCompleted ?? 0);

  // Current + chapter info
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);
  const activeChapterData = chapters[activeChapterIndex];
  const activeChapterTimes = timesData
    ? timesData[activeChapterIndex]
    : undefined;
  const getCategoryId = useCallback(
    (chapterIndex: number) => {
      if (totalChapterCompleted === undefined) {
        return undefined;
      }

      if (chapterIndex < totalChapterCompleted) {
        return "what-i-learned";
      }
      if (chapterIndex === totalChapterCompleted) {
        return "what-i-am-learning";
      }
      return "what-i-will-learn";
    },
    [totalChapterCompleted],
  );
  const activeCategoryId = getCategoryId(activeChapterIndex);
  const chapterCategories = [
    {
      chapterIndex: 0,
      label: "What I Learned",
      id: "what-i-learned",
      className: "chapter-what-i-learned",
    },
    {
      chapterIndex:
        totalChapterCompleted === undefined ? 0 : totalChapterCompleted - 1,
      label: "What I Am Learning",
      id: "what-i-am-learning",
      className: "chapter-what-i-am-learning",
    },
    {
      chapterIndex:
        totalChapterCompleted !== undefined &&
        totalChapterCompleted < chapters.length
          ? totalChapterCompleted
          : undefined,
      label: "What I Will Learn",
      id: "what-i-will-learn",
      className: "chapter-what-i-will-learn",
    },
  ];

  const [subContent, setSubContent] = useState<SubContent>({ type: "review" });

  const onChangeActiveChapterIndex = (index: number) => {
    setActiveChapterIndex(index);
    setSubContent({
      type: "review",
    });
  };

  return (
    <>
      <LegacySeo title={data?.username} />
      <DashboardPage
        heading="Progress Dashboard"
        subHeading={data?.username ?? data?.firstName ?? " "}
      >
        <ErrorMessages errors={[error, timesError, ...(extraErrors ?? [])]} />
        {breadcrumbItems && (
          <Breadcrumbs
            items={breadcrumbItems}
            currentLabel={data?.username ?? data?.firstName ?? "Student"}
          />
        )}
        {(isLoading || timesLoading) && <LoadingSpinner />}

        {children}
        <div className="student-chapter-content">
          <div className="view-student-learning-bar">
            <h4>Game Chapters</h4>
            <div className="chapter-categories">
              {chapterCategories.map((c) => (
                <button
                  key={c.id}
                  type="button"
                  className={clsx(
                    {
                      "chapter-category-active": c.id === activeCategoryId,
                    },
                    c.className,
                  )}
                  disabled={
                    c.chapterIndex === undefined || c.id === activeCategoryId
                  }
                  onClick={() => {
                    if (c.chapterIndex !== undefined) {
                      onChangeActiveChapterIndex(c.chapterIndex);
                    }
                  }}
                >
                  {c.label}
                </button>
              ))}
            </div>
          </div>
          <div className="chapter-balls">
            {totalChapterCompleted !== undefined &&
              chapters.map((c, i) => {
                const isActive = i === activeChapterIndex;
                return (
                  <Fragment key={c.description.join("")}>
                    <button
                      type="button"
                      className={clsx("chapter-ball", {
                        "chapter-ball-active": isActive,
                        // "chapter-ball-down": i % 2 === 1,
                        "chapter-ball-completed": i < totalChapterCompleted,
                        "chapter-ball-current": totalChapterCompleted === i,
                        "chapter-ball-future": i > totalChapterCompleted,
                      })}
                      disabled={isActive}
                      onClick={() => onChangeActiveChapterIndex(i)}
                    >
                      {i + 1}
                    </button>
                    {i < chapters.length - 1 && (
                      <div
                        className={clsx("chapter-ball-joiner", {
                          "chapter-ball-joiner-active":
                            getCategoryId(i) === activeCategoryId &&
                            getCategoryId(i + 1) === activeCategoryId,
                          // "chapter-ball-joiner-uphill": i % 2 === 1,
                        })}
                        // src={ballJoinerImage}
                        // alt="join"
                      />
                    )}
                  </Fragment>
                );
              })}
          </div>
          <div className="chapter-details">
            <div className="chapter-detail chapter-description">
              <h5>Chapter Description</h5>
              {activeChapterData.description.map((d) => (
                <p key={d}>{d}</p>
              ))}
            </div>
            <div className="chapter-detail chapter-topics">
              <h5>Detailed view</h5>
              <div className="topic-buttons">
                <Button
                  type="button"
                  colour="red"
                  size="small"
                  variant={subContent.type === "review" ? "solid" : "outline"}
                  onClick={() =>
                    setSubContent({
                      type: "review",
                    })
                  }
                >
                  Chapter review
                </Button>
                {activeChapterData.topics.map((t, i) => (
                  <div key={t.name}>
                    <Button
                      type="button"
                      colour="red"
                      size="small"
                      variant={
                        subContent.type === "topic" &&
                        subContent.topicIndex === i
                          ? "solid"
                          : "outline"
                      }
                      onClick={() =>
                        setSubContent({
                          type: "topic",
                          topicIndex: i,
                        })
                      }
                    >
                      {t.name}
                    </Button>
                    {t.descriptions.length > 0 && (
                      <ul>
                        {t.descriptions.map((d) => (
                          <li key={d.code}>
                            <strong>{d.code}</strong> {d.content}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="chapter-sub-content">
            {subContent.type === "review" && (
              <div className="chapter-sub-content-review">
                <h5>Chapter Review</h5>
                <ol className="chapter-review-questions">
                  {activeChapterData.reviewQuestions.map((q, i) => {
                    const reviewTimes = (activeChapterTimes?.chapterReviews ??
                      [])[i];
                    return (
                      <li key={q.question} className="chapter-question">
                        <div className="chapter-question-content">
                          {q.question.split("\n").map((line) => (
                            <div key={line}>{line}</div>
                          ))}
                          {q.options && (
                            <ul>
                              {q.options.map((a, j) => (
                                <li key={j}>
                                  <TeX>{a}</TeX>
                                </li>
                              ))}
                            </ul>
                          )}
                          {q.table && (
                            <table className="chapter-question-table">
                              <thead>
                                {q.table.length > 0 && (
                                  <tr>
                                    {q.table[0].map((h) => (
                                      <th key={h}>{h}</th>
                                    ))}
                                  </tr>
                                )}
                              </thead>
                              <tbody>
                                {q.table.slice(1).map((row) => (
                                  <tr key={row[0]}>
                                    {row.map((cell) => (
                                      <td key={cell}>{cell}</td>
                                    ))}
                                    {range(
                                      0,
                                      q.table![0].length - row.length,
                                    ).map((j) => (
                                      <td key={`empty-${j}`} />
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                        <div className="chapter-question-stats">
                          {reviewTimes && (
                            <ActivityProgress times={reviewTimes} />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </div>
            )}
            {subContent.type === "topic" && (
              <div className="chapter-sub-content-topic">
                <h5>{activeChapterData.topics[subContent.topicIndex].name}</h5>
                <ol className="chapter-review-questions">
                  {activeChapterData.topics[
                    subContent.topicIndex
                  ].questions.map((q) => {
                    const activityTimes =
                      q.apiActivityId && activeChapterTimes?.activities
                        ? activeChapterTimes.activities[q.apiActivityId]
                        : undefined;
                    return (
                      <li key={q.title} className="chapter-question">
                        <div className="chapter-question-content">
                          <h6>{q.title}</h6>
                          <div>{q.description}</div>
                          {q.numberOfProblems !== undefined && (
                            <div>Number of problems: {q.numberOfProblems}</div>
                          )}
                          <img src={q.imagePath} alt={q.title} />
                        </div>
                        <div className="chapter-question-stats">
                          {activityTimes && (
                            <ActivityProgress times={activityTimes} />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </div>
            )}
          </div>
        </div>
      </DashboardPage>
    </>
  );
}

export default BaseViewStudent;
