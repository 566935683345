import React, { useState } from "react";
import { Link } from "gatsby";
import { toast } from "react-toastify";
import { format } from "date-fns/format";
import { useQueryClient } from "@tanstack/react-query";
import { StudentDetail } from "~/backendApi/model";
import ErrorMessages from "~/components/ErrorMessages";
import LoadingSpinner from "~/components/LoadingSpinner";
import DashboardDownloadLinks from "~/components/DashboardDownloadLinks";
import formatPercentage from "~/utils/formatPercentage";
import { useSignedInAuthState } from "~/hooks/authContext";
import deleteIcon from "~/images/delete.svg";
import changePasswordIcon from "~/images/key.svg";
import Button from "~/components/Button";
import { useApiMutation } from "~/hooks/apiContext";
import { formatSecondsToTimeMinutes } from "~/utils/formatSecondsToTime";
import { UserType } from "~/model";
import ConfirmRemoveFromSubscriptionModal from "./ConfirmRemoveFromSubscriptionModal";
import DashboardTable from "./DashboardTable";
import { getClassWalletSession } from "~/utils/classWalletSession";
import "./StudentsTable.css";

type StudentsTableProps = {
  readonly viewStudentToPrefix: string;
  readonly showDownloadInstructions: boolean;
  readonly error?: Error;
  readonly isLoading: boolean;
  readonly data?: ReadonlyArray<StudentDetail>;
  readonly hideUserName?: boolean;
  readonly allowFreeTrial?: boolean;
  readonly addStudent?: {
    readonly userType: UserType;
    readonly onAdd: () => void;
  };
  readonly onShowPay?: (student: StudentDetail) => void;
  readonly onDeleteStudent?: (student: StudentDetail) => void;
  readonly onChangePasswordStudent?: (student: StudentDetail) => void;
};

function StudentsTable({
  viewStudentToPrefix,
  data,
  error,
  isLoading,
  addStudent,
  allowFreeTrial,
  hideUserName,
  onShowPay,
  onDeleteStudent,
  onChangePasswordStudent,
}: StudentsTableProps) {
  const { user } = useSignedInAuthState();

  const [pendingRemoveId, setPendingRemoveId] = useState<string | undefined>();
  const pendingRemoveStudent = data?.find((s) => s.id === pendingRemoveId);

  const queryClient = useQueryClient();
  const {
    mutate: createFreeTrialForStudent,
    error: freeTrialError,
    isLoading: freeTrialIsLoading,
  } = useApiMutation<string>(
    (client, id) => client.createFreeTrialForStudent(id),
    {
      onSuccess() {
        queryClient.invalidateQueries(["students"]);
        toast.success("Student free trial created");
      },
    },
  );

  const [hasClassWalletSession] = useState(
    () => getClassWalletSession() !== undefined,
  );

  return (
    <>
      <ConfirmRemoveFromSubscriptionModal
        student={pendingRemoveStudent}
        onRequestClose={() => setPendingRemoveId(undefined)}
        onComplete={() => {
          setPendingRemoveId(undefined);
          toast.success("Student removed from subscription");
        }}
      />
      <ErrorMessages errors={[error, freeTrialError]} />
      {addStudent && (
        <>
          <div className="students-table-intro">
            {addStudent.userType === "parent" && (
              <>
                <ol
                  style={{
                    // maxWidth: 600,
                    textAlign: "left",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: 20,
                  }}
                >
                  <li>
                    Use the 'Add Student' button below to create student IDs.
                  </li>
                  <li>
                    Download the game on your child’s device using the links
                    below, or by searching for ProblemScape in the app stores.
                  </li>
                  <li>
                    Have your child log in with the ID you created, and start
                    playing and learning!
                  </li>
                </ol>
                <p>
                  ProblemScape is currently available for download on iPads,
                  Android tablets, Chromebooks, and PCs (both macOS and
                  Windows).{" "}
                  <strong>
                    Please note that ProblemScape is not compatible with phones.
                  </strong>
                </p>
                <DashboardDownloadLinks />
                {/* {showDownloadInstructions ? (
                  <>
                    <p>
                      Thank you for signing up your child to go on this
                      adventure to master foundational algebra concepts!
                    </p>
                    <p>The next steps are simple!</p>
                    <ol
                      style={{
                        maxWidth: 600,
                        textAlign: "left",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 20,
                      }}
                    >
                      <li>
                        Download the game on your child’s device using the links
                        on top of this page, or by searching for ProblemScape in
                        the app stores
                      </li>
                      <li>Have them login with the id you created, and </li>
                      <li>Start playing and learning!</li>
                    </ol>
                  </>
                ) : (
                  <p>
                    Click on the &quot;Add student&quot; button to create an
                    account for a student and pay for them.
                  </p>
                )} */}
              </>
            )}
            {addStudent.userType === "teacher" && (
              <p>
                If you would like to purchase full access for your students,
                please email{" "}
                <a href="mailto:support@roundedlearning.com">
                  support@roundedlearning.com
                </a>{" "}
                and we&apos;ll get in touch with you to organize access.
              </p>
            )}
          </div>
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 15,
            }}
          >
            <Button
              type="button"
              variant="solid"
              size="small"
              colour="red"
              onClick={addStudent.onAdd}
            >
              Add Student
            </Button>
          </div>
        </>
      )}
      <DashboardTable>
        <thead>
          <DashboardTable.Tr index={0}>
            <th>Student Name</th>
            {!hideUserName && <th>User Name</th>}
            <th>Current Chapter</th>
            <th>Progress in Chapter</th>
            {/* <th>Completed practice level</th> */}
            <th>Completed Review Questions</th>
            <th>Time Spent in Game</th>
            <th>Actions</th>
            {user.type === "parent" && <th>Paid Access</th>}
          </DashboardTable.Tr>
        </thead>
        <tbody>
          {!data && isLoading && (
            <DashboardTable.Tr index={1}>
              <td colSpan={8}>
                <LoadingSpinner />
              </td>
            </DashboardTable.Tr>
          )}
          {!isLoading && !!data && data.length === 0 && (
            <DashboardTable.Tr index={1}>
              <td colSpan={hideUserName ? 7 : 8}>
                <em>No students</em>
              </td>
            </DashboardTable.Tr>
          )}
          {data?.map((d, i) => {
            const pay = (() => {
              if (allowFreeTrial) {
                return {
                  onClick: () => createFreeTrialForStudent(d.id),
                  label: "Enroll (for free)",
                };
              }
              if (!onShowPay) {
                return undefined;
              }
              if (hasClassWalletSession) {
                return {
                  onClick: () => onShowPay(d),
                  label: "Enroll (via ClassWallet)",
                };
              }
              return {
                onClick: () => onShowPay(d),
                label: "Enroll",
              };
            })();
            return (
              <DashboardTable.Tr key={d.id} index={i + 1}>
                <td>
                  <Link to={`${viewStudentToPrefix}/${d.id}/`}>
                    {d.firstName}
                  </Link>
                </td>
                {!hideUserName && <td>{d.username}</td>}
                <td>
                  {d.currentChapter !== undefined &&
                    `${d.currentChapter} of ${d.totalChaptersOfTheGame}`}
                </td>
                <td>
                  {d.currentChapterProgress !== undefined &&
                    formatPercentage(d.currentChapterProgress)}
                </td>
                <td>
                  {(d.completedReviewQuestions > 0 ||
                    d.totalReviewQuestions > 0) &&
                    `${d.completedReviewQuestions} of ${d.totalReviewQuestions}`}
                </td>
                <td>
                  {d.timeSpent !== undefined &&
                    formatSecondsToTimeMinutes(d.timeSpent)}
                </td>
                <td>
                  <div className="table-actions-col">
                    {onChangePasswordStudent && (
                      <Button
                        type="button"
                        variant="solid"
                        size="small"
                        colour="blue"
                        onClick={() => onChangePasswordStudent(d)}
                      >
                        <img
                          src={changePasswordIcon}
                          alt="Change password"
                          className="button-icon"
                        />
                      </Button>
                    )}
                    {onDeleteStudent && (
                      <Button
                        type="button"
                        variant="solid"
                        size="small"
                        colour="red"
                        onClick={() => onDeleteStudent(d)}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          className="button-icon"
                        />
                      </Button>
                    )}
                  </div>
                </td>
                {/* Atm we only have one off payments */}
                {user.type === "parent" && (
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {d.isSubscribed && (
                        <>Until {format(d.subscribedUntil, "d/M/yyyy")}</>
                      )}
                      {!d.isSubscribed && pay && (
                        <Button
                          type="button"
                          variant="solid"
                          size="small"
                          colour="red"
                          onClick={pay.onClick}
                          disabled={freeTrialIsLoading}
                        >
                          {pay.label}
                        </Button>
                      )}
                    </div>
                  </td>
                )}
              </DashboardTable.Tr>
            );
          })}
        </tbody>
      </DashboardTable>
    </>
  );
}

export default StudentsTable;
